import React from 'react'
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image'
import Layout from '../../components/Layout'

class Election2021BondPage extends React.Component {
  render() {
    return (
  <Layout>
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h1 className="title is-size-1">2021 Bond Election</h1>
              <p>See documents and articles related to the 2021 bond election.</p>
              <hr />
              <div className="news-preview-container">

                <div className="news-preview-item">
                  <div className="news-preview-content">
                    <p className="is-size-5">
                    <a href="/assets/Grand Lakes MUD 4 - 2021-bond-exhibits.en.pdf">Overview, Engineering and Financials</a>
                    <br /><small className="accent-color">April 8, 2021</small></p>
                  </div>
                  <div className="news-preview-footer">
                    <a className="button is-white" href="/assets/Grand Lakes MUD 4 - 2021-bond-exhibits.en.pdf">View</a>
                  </div>
                </div>

                <div className="news-preview-item">
                  <div className="news-preview-content">
                    <p className="is-size-5">
                    <a href="/assets/Grand Lakes MUD 4 - 2021-bond-exhibits.es.pdf">Visión general, Ingeniería y Finanzas</a>
                    <br /><small className="accent-color">April 8, 2021</small></p>
                  </div>
                  <div className="news-preview-footer">
                    <a className="button is-white" href="/assets/Grand Lakes MUD 4 - 2021-bond-exhibits.es.pdf">View</a>
                  </div>
                </div>

                <div className="news-preview-item">
                  <div className="news-preview-content">
                    <p className="is-size-5">
                    <a href="/assets/01 Order Calling Bond Election (english and spanish 2-24-2021).pdf">ORDER CALLING BOND ELECTION<br />(ORDEN PARA CONVOCAR UNA ELECCIÓN DE BONOS)</a>
                    <br /><small className="accent-color">April 6, 2021</small></p>
                  </div>
                  <div className="news-preview-footer">
                    <a className="button is-white" href="/assets/01 Order Calling Bond Election (english and spanish 2-24-2021).pdf">View</a>
                  </div>
                </div>

                <div className="news-preview-item">
                  <div className="news-preview-content">
                    <p className="is-size-5">
                    <a href="/assets/ii Election - Notice of Voting Order Priority-2021.pdf">NOTICE OF VOTING ORDER PRIORITY<br />(AVISO DE PRIORIDAD DE ORDEN DE VOTACIÓN)</a>
                    <br /><small className="accent-color">April 6, 2021</small></p>
                  </div>
                  <div className="news-preview-footer">
                    <a className="button is-white" href="/assets/ii Election - Notice of Voting Order Priority-2021.pdf">View</a>
                  </div>
                </div>

                <div className="news-preview-item">
                  <div className="news-preview-content">
                    <p className="is-size-5">
                    <a href="/assets/iii Sample Ballot-2021.pdf">SAMPLE BALLOT<br />(BOLETA DE MUESTRA)</a>
                    <br /><small className="accent-color">April 6, 2021</small></p>
                  </div>
                  <div className="news-preview-footer">
                    <a className="button is-white" href="/assets/iii Sample Ballot-2021.pdf">View</a>
                  </div>
                </div>

                <div className="news-preview-item">
                  <div className="news-preview-content">
                    <p className="is-size-5">
                    <a href="/assets/Notice of Appointed Agent 2021.pdf">NOTICE OF APPOINTED AGENT<br />(AVISO DE AGENTE NOMINADO)</a>
                    <br /><small className="accent-color">March 12, 2021</small></p>
                  </div>
                  <div className="news-preview-footer">
                    <a className="button is-white" href="/assets/Notice of Appointed Agent 2021.pdf">View</a>
                  </div>
                </div>

              </div>
              <div className="section">
              <hr />
              <p><strong>English</strong><br />
              If you need this election document provided in Spanish, please send us a message requesting the translated document using the <Link to="/contact">“Contact Us”</Link> link on this website.</p>

              <p><strong>Spanish</strong><br />
              Si necesita que le entreguen este documento sobre la elección en español, envíenos un mensaje solicitando el documento traducido a través del enlace <Link to="/contact">“Contact Us”</Link> de este sitio web.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)
}
}

export default Election2021BondPage
